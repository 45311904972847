$(function() { 'use strict';

  var GLOBAL = window;


  // Rotator nav
  (function() {
    var $rotator = $('#main-rotator');
    var $page = $rotator.find('.cycle-pager > li');

    $rotator.find('.cycle-slide').map(function(index) {
      var content = $(this).find('img').attr('alt');
      $page.filter(':eq(' + index + ')').html('<span>' + content + '</span>');
    });
  }());


  // Vitrine tabs, card tabs
  $('#vitrine-tabs, #card-tabs')
    .find('.vitrine-tabs-menu > ul, .product-anchors-menu > ul')
      .append('<li class="tabs-menu-mark" />')
      .end()
    .on('tabscreate tabsactivate', function(ev, ui) {
      var $tab = ui.tab ? $(ui.tab) : $(ui.newTab);
      $(this).find('.tabs-menu-mark').css({
        width: $tab.width(),
        left: $tab.is(':first-child') ? 0 : $tab.position().left + 15
      });
    }).tabs({ active: 0 });


  // Main carousels
  (function() {
    function initCarousel(carousels) {
      return $.each(carousels, function() {
        if ($('.carousel li', this.container).length < this.size) return;

        var $carousel = $(this.container);

        $('.carousel-container', this.container).jcarousel({
          'animation' : 'fast',
          'wrap' : 'circular'
        });

        $('.carousel-prev', this.container).jcarouselControl({ target: '-=1' });
        $('.carousel-next', this.container).jcarouselControl({ target: '+=1' });

        $carousel.touchwipe({
          wipeLeft: function() {
            $carousel.find('.carousel-next').trigger('click');
          },
          wipeRight: function() {
            $carousel.find('.carousel-prev').trigger('click');
          }
        });
      });
    }

    initCarousel([
      // { container: '#main-partners-carousel', size: 3 },
      // { container: '#main-certificates-carousel', size: 5 }
    ]);
  }());


  // PSWP
  $DOCUMENT.on('click.pswp', '.js-pswp-item', function(ev) {
    ev.preventDefault();

    var elem = this;

    var $elem = $(elem);
    var $items = $elem.closest('.js-pswp-group').find('.js-pswp-item');

    var D = $.Deferred();
    var sources = [];

    $items.each(function(k, v) {
      var $this = $(this);
      var img = new Image();


      $.data(this, 'ID', k);

      img.src = $this.attr('href');
      img.addEventListener('load', function() {
        sources[k] = {
          src: img.src,
          w: img.width,
          h: img.height
        };

        return D.resolve();
      });
    });


    $.when(D).then(function() {
      setTimeout(function() {
        var pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, sources, {
          index: $.data(elem, 'ID')
        });

        return pswp.init();
      }, 150);
    });
  });


  // Brands menu
  $WINDOW.on('load.scrollbar', function() {
    $('#brands-carousel').perfectScrollbar();
  });


  // Toggle panel
  $DOCUMENT.on('click.toogle-panel', '.js-toggle-panel', function() {
    $(this).closest('.filters-panel').toggleClass('is-collapsed is-expanded');
  });

  // Toggle panel menu
  $DOCUMENT.on('click', '.js-toggle-panel-menu', function(ev) {
    ev.preventDefault();

    var $button = $(this).find('span');
    var $menu = $button.closest('.filters-panel').find('.menu');

    if ($menu.hasClass('is-collapsed')) $button.html('Скрыть');
    if ($menu.hasClass('is-expanded')) $button.html('Показать все');

    $menu.toggleClass('is-collapsed is-expanded');
  });


  $DOCUMENT.on('yiiListViewUpdated', function(ev) {
    $('select').selectric({ disableOnMobile: false });
  });


  // Hide pages
  $DOCUMENT.on('yiiListViewUpdated domready', function() {
    var $pagesPanel = $('.js-pages');

    if (!$pagesPanel.find('.pager').length) $pagesPanel.hide();
  }).trigger('domready');


  // Add to basket animation
  var basketAnimate = function(pic, panelHeader) {
    if ( pic == undefined || pic.size() == 0 ) return;

    var clonedPic = pic.clone().css({
      position: 'fixed',
      left: pic.offset().left - $(window).scrollLeft(),
      top: pic.offset().top - $(window).scrollTop(),
      height: pic.height(),
      width: pic.width()
    }).addClass('movin-to-basket');
    $('body').append(clonedPic);

    var targetPos = { top: panelHeader.offset().top - $(window).scrollTop(), left: panelHeader.offset().left - $(window).scrollLeft() };
    clonedPic.css({
      top: targetPos.top,
      left: targetPos.left,
      opacity: 0
    }).addClass('scale');

    setTimeout(function(){
      clonedPic.remove();
    }, 500)
  };

  $('#content').on('click', '.to-basket:not(.already-in-basket)', function() {
    var pic = $(this).closest('.product').find('.product--image-inner img'),
        basket = $('.header--basket');
    basketAnimate(pic, basket);
  });
});
