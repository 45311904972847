$(function() { 'use strict';

  // Const
  var GLOBAL = window;

  GLOBAL.$WINDOW = $(window);
  GLOBAL.$DOCUMENT = $(document);
  GLOBAL.$HTML = $('html');
  GLOBAL.$BODY = $(document.body);

  GLOBAL.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) != null;
  GLOBAL.IS_DESKTOP = !IS_MOBILE;

  GLOBAL.IS_IOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
  GLOBAL.IS_IE8 = navigator.appVersion.indexOf("MSIE 8") !== -1;

  if (IS_MOBILE) $HTML.addClass('is-mobile');
  if (IS_DESKTOP) $HTML.addClass('is-desktop');
  if (IS_IOS) $HTML.addClass('is-ios');

  GLOBAL.PSWP = {
    TMPL: document.getElementById('pswp'),
    UI: PhotoSwipeUI_Default
  };


  // Alertify defaults
  alertify.set({
    buttonReverse: true,
    labels : {
      ok : 'Да',
      cancel : 'Нет'
    }
  });


  // Maskedinput phone
  $('input[type="tel"]').mask('+7 (999) 999-99-99', {autoclear: false});


  // Common tabs
  $('.js-common-tabs').tabs();


  // Selectric
  $('select').selectric({ disableOnMobile: false });


  // OverlayLoader init
  $DOCUMENT.on('click.overlay', '.js-overlay', function(ev) {
    ev.preventDefault();
    $.overlayLoader(true, $($(this).attr('href')));
  });


  // OverlayLoader autofocus
  $DOCUMENT.on('overlayLoaderShow', function(ev, $node) {
    $node.find('.js-autofocus-inp').focus();
  });


  // OverlayLoader close
  $DOCUMENT.on('click.overlay', '.js-popup-close', function(ev) {
    ev.preventDefault();
    $.overlayLoader(false, $(this).closest('.popup'));
  });


  // Spinner
  $DOCUMENT.on('mousedown.spinner', '.js-spinner-control', function() {
    var $control = $(this);
    var $input = $control.siblings('.js-spinner-inp');
    var incTimeout, incInterval, decTimeout, decInterval;

    $control.on('mouseup.spinner', clearAll);
    $control.on('mouseleave.spinner', $control, clearAll);

    if ($control.hasClass('js-down')) {
      decVal(); decTimeout = setTimeout(function() {
        decInterval = setInterval(decVal, 70);
      }, 300);
    }

    if ($control.hasClass('js-up')) {
      incVal(); incTimeout = setTimeout(function() {
        incInterval = setInterval(incVal, 70);
      }, 300);
    }

    function incVal() {
      $input.data('amount', (parseInt($input.val()) + 1));
      $input.val(parseInt($input.val()) + 1).change();
    }

    function decVal() {
      if (parseInt($control.siblings('.js-spinner-inp').val()) > 1) {
        $input.data('amount', (parseInt($input.val()) - 1));
        $input.val(parseInt($input.val()) - 1).change();
      }
    }

    function clearAll() {
      clearTimeout(decTimeout); clearInterval(decInterval);
      clearTimeout(incTimeout); clearInterval(incInterval);
    }
  });


  // Anchors menu
  $DOCUMENT.on('click.anchors-menu', '.js-anchors-menu a', function(ev) {
    ev.preventDefault();

    var $target = $($(this).attr('href'));
    var duration = Math.abs($target.offset().top - $(window).scrollTop()) * 0.3;

    $(window).scrollTo($target, {
     duration: duration
    });
  });


  // Carousel controls
  $DOCUMENT
    .on('jcarouselcontrol:active', 'carousel-contorl', function() {
      $(this).removeClass('is-disabled');
    })
    .on('jcarouselcontrol:inactive', 'carousel-contorl', function() {
      $(this).addClass('is-disabled');
    });

  // ios nth-child hack
  if (IS_IOS) $('.four-columns > .column:nth-child(4n)').css('margin-right', 0);

  // mobile menu
  if (IS_MOBILE) {
    $('#main-menu').on('click', 'a', function(ev) {
      var $link = $(this);
      var $item = $link.parent('li');

      if ($item.find('ul').length) {
        ev.preventDefault();

        if (!$item.hasClass('is-active-mobile')) {
          $item.addClass('is-active-mobile');
          $item.siblings().removeClass('is-active-mobile');
        } else {
          $item.removeClass('is-active-mobile');
          window.location.href = $link.attr('href');
        }
      }
    });
  }
});
